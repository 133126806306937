import { graphql } from 'gatsby';
import React from 'react';

import ContactForm from '@components/Form/ContactForm';
import Columns from '@components/Layout/Columns/Columns';
import { MotionContent } from '@components/Layout/Content/Content';
import Content from '@components/Layout/Content/Content';
import Grid from '@components/Layout/Grid/Grid';
import Lead from '@components/Layout/Lead/Lead';
import PageSection from '@components/Layout/PageSection/PageSection';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import Accordion from '@components/Molecule/Accordion/Accordion';
import InteractiveMap from '@components/Molecule/Map/Map';
import { MotionCopy } from '@components/Typography/Copy';
import Copy from '@components/Typography/Copy';
import H, { MotionH } from '@components/Typography/H';

type Office = {
	continent: string;
	country: string;
	company_name: string;
	address?: string;
	telephone: string;
	mobile?: string;
	email_address: string;
	website_url?: string;
};

type Props = {
	data: {
		prismicContact: {
			data: {
				company_name: string;
				address_line_1: string;
				address_line_2: string;
				town_city: string;
				county: string;
				country: string;
				postcode: string;
				telephone: string;
				email_address: string;
				map_location: {
					latitude: number;
					longitude: number;
				};
				location: Office[];
			};
		};
	};
};

export default function ContactPage({ data }: Props) {
	const headOffice = data.prismicContact.data;
	const offices = data.prismicContact.data.location;

	const groupedOffices: Record<string, Office[]> = offices.reduce(
		(acc, office) => {
			const { continent } = office;
			if (!acc[continent]) acc[continent] = [];
			acc[continent].push(office);
			return acc;
		},
		{} as Record<string, Office[]>
	);

	return (
		<Page>
			<SEO
				title="Contact | UHV Design"
				description="Got a project you'd like to discuss? Contact us at sales@uhvdesign.com or use our contact page to find your nearest UHV Design representative. Contact us."
			/>

			<PageSection>
				<div>
					<MotionH level={2} as={'h1'}>
						Contact
					</MotionH>
				</div>

				<Columns gap="md">
					<div className="column--space">
						<div>
							<Lead>
								<MotionH level={4} as={'h2'}>
									Head office
								</MotionH>
							</Lead>

							<Grid spacing type="fixed">
								<MotionCopy as={'address'}>
									{headOffice.company_name}
									<br />
									{headOffice.address_line_1}
									<br />
									{headOffice.address_line_2}
									<br />
									{headOffice.town_city}
									<br />
									{headOffice.county}
									<br />
									{headOffice.country}, {headOffice.postcode}
								</MotionCopy>

								<MotionContent>
									<a href={`tel:+${headOffice.telephone}`}>
										T: +{headOffice.telephone}
									</a>

									<a
										href={`mailto:${headOffice.email_address}`}>
										E: {headOffice.email_address}
									</a>
								</MotionContent>
							</Grid>
						</div>

						<InteractiveMap
							latitude={headOffice.map_location.latitude}
							longitude={headOffice.map_location.longitude}
						/>
					</div>

					<div>
						<ContactForm />
					</div>
				</Columns>
			</PageSection>

			<PageSection background="grey" padding="sm">
				<Columns stretch="latter">
					<Lead>
						<H level={3} as={'h2'}>
							Our other locations
						</H>
					</Lead>

					{Object.keys(groupedOffices).length > 0 && (
						<Grid>
							{Object.entries(groupedOffices).map(
								([continent, countries]) => (
									<Accordion
										key={continent}
										label={continent}>
										<Grid type="twoColumn">
											{countries.map((country, index) => (
												<div
													className="column--space"
													key={index}>
													<Content spacing="none">
														<Copy weight="bold">
															{country.country}
														</Copy>
														<Copy>
															{
																country.company_name
															}
														</Copy>
														{country.address && (
															<Copy>
																{
																	country.address
																}
															</Copy>
														)}
													</Content>

													<Content spacing="none">
														<a
															href={`tel:+${country.telephone}`}>
															T:{' +'}
															{country.telephone}
														</a>

														{country.mobile && (
															<a
																href={`tel:+${country.mobile}`}>
																M:{' +'}
																{country.mobile}
															</a>
														)}

														<a
															href={`mailto:${country.email_address}`}>
															E:{' '}
															{
																country.email_address
															}
														</a>

														{country.website_url && (
															<a
																href={
																	country.website_url
																}
																target="_blank"
																rel="noopener noreferrer">
																W:{' '}
																{
																	country.website_url
																}
															</a>
														)}
													</Content>
												</div>
											))}
										</Grid>
									</Accordion>
								)
							)}
						</Grid>
					)}
				</Columns>
			</PageSection>
		</Page>
	);
}

export const query = graphql`
	query {
		prismicContact {
			data {
				company_name
				address_line_1
				address_line_2
				town_city
				county
				country
				postcode
				telephone
				email_address
				map_location {
					latitude
					longitude
				}
				location {
					continent
					country
					company_name
					address
					telephone
					mobile
					email_address
					website_url
				}
			}
		}
	}
`;
