// extracted by mini-css-extract-plugin
export var boldFontWeight = "Form-module--bold--font-weight--9b756";
export var bounds = "Form-module--bounds--3379f";
export var checkbox = "Form-module--checkbox--b84fe";
export var fieldGroup = "Form-module--field-group--47883";
export var form = "Form-module--form--316b3";
export var input = "Form-module--input--dfc45";
export var label = "Form-module--label--1a2d5";
export var layout = "Form-module--layout--8c9ce";
export var lgGap = "Form-module--lg--gap--babf7";
export var lineItem = "Form-module--line-item--3bc7f";
export var mdGap = "Form-module--md--gap--b3156";
export var normalFontWeight = "Form-module--normal--font-weight--75701";
export var row = "Form-module--row--d63d1";
export var smGap = "Form-module--sm--gap--d0862";
export var textArea = "Form-module--text-area--493b5";
export var tick = "Form-module--tick--40c2a";